import React from "react";
import { graphql } from "gatsby";
import Layout from "gatsby-theme-hypersite/src/layout";
import { makeStyles, Typography } from "@material-ui/core";
import { Scorecard, FederalSummary } from "../components/states/sections";
import SectionNavigation from "../components/SectionNavigation";
import { Block } from "@hyperobjekt/material-ui-website";
import HomeMap from "../components/home/HomeMap";
import Table from "../components/home/HomeTable";
import content from "../../content/lang/federal.json";
import FacilitiesMapTooltip from "../components/states/visuals/FacilitiesMapTooltip";
import { useActiveMetric } from "../common/hooks";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  block: {
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(8, 3),
    },

    "&#map": {
      // prevent blue box around svg on select
      "& path": {
        outline: "none",
      },
      "& .home-map > .MuiBox-root": {
        padding: 0,
      },
      "& .map-legend": {
        background: theme.palette.background.paper,
      },
    },
  },
}));

const FederalTemplate = ({ data }) => {
  // classes used on this page
  const classes = useStyles();
  const state = "Federal";
  const [currentSection, setCurrentSection] = React.useState("aggregates");

  // sections for section nav
  const sections = Object.values(content);
  const sectionNav = sections.map((section) => ({
    id: section.id,
    name: section.link,
  }));

  /** Set the current section when visibility changes */
  const handleVisibilityChange = (isVisible, e) => {
    // ignore if not visible
    if (!isVisible || !e || !e.target) return;
    const sectionId = e.target.id;
    setCurrentSection(sectionId);
  };

  // common props among all blocks
  const BlockProps = {
    className: classes.block,
    IntersectionOptions: { threshold: 0.5 },
    onVisibleChange: handleVisibilityChange,
  };

  const metric = useActiveMetric();

  return (
    <Layout title={state}>
      <SectionNavigation current={currentSection} sections={sectionNav} />
      <Block id="aggregates" {...BlockProps}>
        <Typography variant="h2" className={classes.title}>
          Federal Facilities in the United States
        </Typography>
        <FederalSummary data={data} lang={content["aggregates"]} />
      </Block>
      <HomeMap
        id="map"
        isFederal={true}
        categories={["federal"]}
        lang={content["map"]}
        bgcolor="background.paper"
        {...BlockProps}
      />
      <FacilitiesMapTooltip
        group={"residents"}
        metric={metric}
        isFederal={true}
      />
      <Table
        id="facilities"
        isFederal={true}
        categories={["federal"]}
        lang={content.table}
        bgcolor="background.default"
        {...BlockProps}
      />
      <Block id="scorecard" bgcolor="background.paper" {...BlockProps}>
        <Scorecard state="federal" data={data} lang={content["scorecard"]} />
      </Block>
    </Layout>
  );
};

FederalTemplate.propTypes = {};

export const query = graphql`
  query {
    allAggregates(filter: { jurisdiction: { eq: "federal" } }) {
      edges {
        node {
          measure
          val
        }
      }
    }
    allVaccines(filter: { isFederal: { eq: true } }) {
      nodes {
        residents {
          population
        }
        staff {
          population
        }
      }
    }
    allFacilities(filter: { jurisdiction: { eq: "federal" } }) {
      edges {
        node {
          city
          coords
          id
          jurisdiction
          name
          residents {
            active
            active_rate
            confirmed
            confirmed_rate
            deaths
            deaths_rate
            tested
            tested_rate
          }
          staff {
            active
            confirmed
            deaths
          }
        }
      }
    }
    allPrisonReleases(filter: { state: { eq: "Federal BOP" } }) {
      edges {
        node {
          id
          releases
          facility
          source
          state
          capacity
        }
      }
    }
    allFilings(filter: { state: { eq: "federal" } }) {
      edges {
        node {
          courtCount
          facilityCount
          granted
          total
        }
      }
    }
    scorecard: allScorecard(filter: { state: { eq: "Federal (BOP)" } }) {
      nodes {
        score
        date
        machine
        regularly
        history
        defined
        cases_residents
        deaths_residents
        active_residents
        tests_residents
        population_residents
        vaccinations_residents
        booster_residents
        cases_staff
        deaths_staff
        tests_staff
        vaccinations_staff
        active_staff
        population_staff
        booster_staff
      }
    }
  }
`;

export default FederalTemplate;
